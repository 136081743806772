@import-normalize;
@import '~antd/dist/reset.css';

html,
body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-btn > span {
  font-size: 14px !important;
}
