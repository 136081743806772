.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: rgb(250, 70, 70);
  font-weight: 600;
}
