.smsBtn {
  margin-top: 48px;
  width: 100%;
  font-size: 14px !important;
}

@media screen and (max-width: 820px) {
  .smsBtn {
    margin-top: 0px;
    margin-bottom: 24px;
  }
}
