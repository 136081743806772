.upload {
  display: flex;
  color: #5c5c5c;

  & > img {
    margin-right: 24px;
    height: 290px;
    width: 225px;
    object-fit: cover;
  }
}

@media screen and (max-width: 820px) {
  .upload {
    & > img {
      height: 191px;
      width: 132px;
    }
  }
}

@media screen and (max-width: 350px) {
  .upload {
    flex-direction: column;
    align-items: center;
  }
}
