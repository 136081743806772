.AppDefault {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;

  input,
  .ant-select-selector {
    border: 1px solid #c4c4c4 !important;
    border-radius: 4px !important;
  }

  .ant-select-selector {
    text-transform: uppercase;
    color: #5c5c5c;
    font-weight: bold;
  }

  h2 {
    color: rgba(0, 0, 0, 0.85);
  }

  label {
    color: #5c5c5c;
  }

  button {
    border: 2px solid #ebedee;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: #5c5c5c;
    white-space: break-spaces;
    height: fit-content;
    min-height: 40px;
    img {
      margin-right: 10px;
    }
  }
}

.AppLight {
  h1,
  h2,
  label,
  div,
  .ant-form-item-label > label {
    color: white;
  }

  .ant-form-item-explain {
    div {
      color: #ff4d4f !important;
    }
  }

  button {
    border: none;
  }

  .anticon-paper-clip {
    color: inherit !important;
  }

  .ant-upload-list-item-info {
    max-width: 150px;
  }

  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: transparent !important;
  }

  .anticon-delete {
    color: white !important;
  }

  .ant-upload-list-item-card-actions-btn {
    min-height: 10px;
  }
}

@media screen and (max-width: 820px) {
  .ant-upload-list-item-card-actions-btn {
    opacity: 1 !important;
  }
}
