.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.label {
  display: flex;
}

.itemTitle {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  font-weight: 400;
  color: var(--system_black);

  margin-bottom: 4px;
  margin-left: 2px;
}

.input {
  font-weight: 400;
  border: 1px solid var(--admin-theme_Grey_02) !important;
  border-radius: 4px;
}

.directionRow {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.directionColumn {
  flex-direction: column;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.align-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.labelAbove {
  flex-direction: column;
  flex-wrap: wrap;
}

.labelLeft {
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
}

.customInputAcc {
  font-weight: 400;
  border: 1px solid var(--admin-theme_Grey_02) !important;
  border-radius: 4px;
  width: 389px;
}

@media (max-width: 820px) {
  .wrapper {
    flex-direction: column;
    align-items: stretch;
  }

  .label {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .customInputAcc {
    width: 100%;
  }

  .itemTitle {
    width: 100%;
  }

  .input {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .label {
    width: 100% !important;
  }
}
