.form {
  width: 850px;
  padding-bottom: 70px;

  h1 {
    font-weight: bold;
    font-size: 30px;
    line-height: 32px;
    margin-bottom: 56px;
  }

  h2 {
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  .submitBtn {
    width: 100%;
    margin-bottom: 12px;
    color: white;
    border: none;
  }

  .link {
    text-align: center;
    a {
      color: rgb(45, 119, 70);
    }
  }
}

@media screen and (max-width: 820px) {
  .form {
    padding: 16px;
    h1 {
      font-size: 24px;
      line-height: 38px;
      margin-bottom: 16px;
    }
  }
}
