.radio {
  display: flex;
  justify-content: space-between;
}

.tournament,
.match {
  display: flex;
  align-items: center;
  div {
    color: black;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.tournament {
  img {
    margin-right: 16px;
  }
}

.match {
  justify-content: space-between;
}

.selected {
  margin-top: 5px;
  padding: 4px 10px;
  background-color: #ebedee;
  border-radius: 4px;
  color: black !important;
  div {
    color: black;
  }
}
