.header {
  margin: 81px 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  justify-content: space-between;
  img {
    width: 56px;
    height: 56px;
    margin-right: 18px;
  }
}

.header_logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__display_container_language_container {
  display: flex;
  margin-left: 10px;
  gap: 5px;
}

.header__text_language {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  &:hover {
    cursor: pointer;
  }
  &_active {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    &:hover {
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 820px) {
  .header {
    margin: 40px 0;
  }
}
